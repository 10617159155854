@import 'landing';
@import 'nav';
@import 'photography';
@import 'webDesign';
@import 'info';
@import 'loadingSpinner';

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Rubik+Glitch&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap');

:root {
    /* color variables */
    --ivory: #F9F7E8;
    --darkgray: #212930;
    --gray: #787276;
    --blue: #2b9bd2;

    /* font variables */
    --font: 'Manrope', sans-serif;
    --font-text: 'Playfair Display', sans-serif;
    --bold: 'Anton', sans-serif;
    --logo: 'Cedarville Cursive', cursive;

    /* font weight variables */
    --main: 800;
    --text: 400;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    overflow-x: hidden;
    // overflow-y: scroll;
}

body {
    margin: 0;
    background-color: var(--ivory);
}