.landing-section {
  margin-left: 10%; 
  margin-right: 10%;
}
.landing-container {
  height: 85vh;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.landing-container img {
  height: 70%;
}


@media (max-width: 920px) {
}

@media (max-width: 760px) {
}
