.navbar {
  color: var(--darkgray);
  background-color: var(--ivory);
  display: grid;
  grid-template-columns: 13% 1fr 0.8fr 10%;
  height: 80px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar.active {
  background-color: var(--ivory);
  color: var(--darkgray);
  border-bottom: 1px var(--darkgray) solid;
}

.navbar-focused {
  color: var(--darkgray);
  background-color: var(--ivory);
  border-bottom: 1px var(--darkgray) solid;
  display: grid;
  grid-template-columns: 10% 1fr 1fr 10%;
  height: 80px;
  background: transparent;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-focused.active {
  color: var(--darkgray);
  background-color: var(--ivory);
  border-bottom: 1px var(--darkgray) solid;
}

.mobile-navbar {
  display: none;
}

.nav-items {
  display: flex;
  justify-content: space-evenly;
  letter-spacing: 1px;
  text-transform: capitalize;
}

.nav-item {
  font-size: 16px;
  list-style-type: none;
  font-family: var(--logo);
  font-weight: var(--text);
}

.nav-link {
  text-decoration: none;
  color: var(--black);
}

.nav-link:hover {
  color: var(--gray);
}

#logo {
  grid-column: 2/3;
  font-size: 22px;
  letter-spacing: 2px;
  font-family: var(--logo);
  font-weight: var(--text);
  color: var(--darkgray);
  cursor: pointer;
}

#mobile-logo {
  font-size: 24px;
  letter-spacing: 2px;
  font-family: var(--logo);
  font-weight: var(--text);
  color: var(--darkgray);
  margin-left: 5%;
  margin-top: 15px;
}

@media (max-width: 1050px) {
  .nav-item {
    font-size: 12px;
  }

  #logo {
    font-size: 16px;
  }
}

@media (max-width: 920px) {
  .navbar {
    display: none;
  }

  .mobile-navbar {
    display: block;
  }
}

/* mobile navbar */

.menu-wrap {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 3;
}

.menu-wrap .toggler {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  width: 50px;
  height: 50px;
  opacity: 0;
}

.menu-wrap .hamburger {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 70px;
  height: 70px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Hamburger line */
.menu-wrap .hamburger > div {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: var(--darkgray);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
}

/* top and bottom lines */
.menu-wrap .hamburger > div:before,
.menu-wrap .hamburger > div:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -12px;
  width: 100%;
  height: 3px;
  background: inherit;
}

/* moves bottom line down */
.menu-wrap .hamburger > div:after {
  top: 12px;
}

/* toggler animate */
.menu-wrap .toggler:checked + .hamburger > div {
  transform: rotate(135deg);
  background-color: var(--ivory);
}

/* turn lines into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
  top: 0;
  transform: rotate(90deg);
}

/* rotate on hover when checked */
.menu-wrap .toggler:checked:hover + .hamburger > div {
  transform: rotate(225deg);
}

/* show menu */
.menu-wrap .toggler:checked ~ .menu {
  visibility: visible;
}
.menu-wrap .toggler:checked ~ .menu > div {
  transform: scale(1);
  transition-duration: 1s;
}

.menu-wrap .toggler:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.no-menu {
    display: none;
}

.menu-wrap .menu > div {
  background-color: var(--darkgray);
  border-radius: 50%;
  width: 300vw;
  height: 300vw;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  transition: all 0.4s ease;
}

.menu-wrap .menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
  opacity: 0;
  transition: opacity 0.4s ease;
}

.menu-wrap .menu > div > div > ul > li {
  list-style: none;
  color: var(--ivory);
  font-family: var(--logo);
  font-weight: var(--main);
  font-size: 3rem;
  padding: 1rem;
}

.menu-wrap .menu > div > div > ul > li > a {
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

@media only screen and (max-device-width: 415px) and (max-device-height: 920px) {
  .menu-wrap .menu > div > div > ul > li {
    font-size: 3rem;
  }

  .menu-wrap .menu > div {
    width: 250vw;
    height: 250vw;
  }

  .nav-link {
    font-family: var(--logo);
    letter-spacing: 2px;
  }
}
