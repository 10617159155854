.photo-project-section {
  text-align: center;
}

.photo-projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.photo-section {
  text-align: center;
  margin-right: 10%;
  margin-left: 10%;
}

.photo {
  max-width: 50vw;
  max-height: 80vh;
  margin-bottom: 100px;
}

.photo-title {
  font-family: var(--font);
  font-weight: var(--text);
  font-size: 16px;
  color: var(--gray);
}

.photo-project {
  width: 30vw;
  margin-bottom: 10px;
}

.photo-project:hover {
  cursor: pointer;
}

.photo-project-title {
  font-family: var(--font-text);
  font-style: italic;
  font-size: 14px;
  color: var(--gray);
  margin-bottom: 50px;
}

.controlsMessage {
  font-family: var(--font);
  font-weight: var(--text);
  color: var(--gray);
  opacity: 1;
  transition: 3s;
}

.hide {
  opacity: 0;
  transition: 3s;
}

@media (max-width: 920px) {
  .photo-project-section {
    margin-top: 100px;
  }
  .photo-project {
    width: 95vw;
  }

  .photo-section {
    margin-right: 0;
    margin-left: 0;
    margin-top: 15%;
  }

  .photo {
    max-width: 95vw;
    margin-top: 100px;
  }

  .photo-container {
    width: 95vw;
    text-align: center;
  }
}

@media (max-width: 450px) {
  .photo {
    margin-bottom: 50px;
  }
}
